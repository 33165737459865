import {
  createFetchOptions,
  fetchHandler,
  useFetch,
  useFetchAll,
  useMutate,
  useQueryClient,
} from "./use-fetch";
import { useProperty } from "./use-property";

const TICKET_QUERY_KEY = "tickets";

export const useTickets = (
  query = { status: "open" },
  opts = {
    includeStats: false,
    propertyIds: [],
  },
) => {
  const { currentPropertyId } = useProperty();
  const propertyIds =
    opts.propertyIds?.length > 0 ? opts.propertyIds : [currentPropertyId];
  const fetchOptions = createFetchOptions(currentPropertyId, TICKET_QUERY_KEY);
  const TICKETS = fetchOptions.key;
  const ticketReqHeaders = fetchOptions.headers;

  const ticketQueries = propertyIds.map((propertyId) => {
    const fetchOptions = createFetchOptions(propertyId, TICKET_QUERY_KEY);
    const TICKETS = fetchOptions.key;
    const ticketReqHeaders = fetchOptions.headers;

    return {
      queryFn: fetchHandler({
        useCase: "ListTickets",
        query: query,
        headers: ticketReqHeaders,
      }),
      queryKey: [...TICKETS, query],
      staleTime: 1000 * 60 * 5, // 5 minutes
      refetchOnWindowFocus: true,
      retry: 3,
      meta: {
        cacheById: true,
        excludeQuery: query,
        propertyId: propertyId,
      },
      placeholderData: [],
    };
  });

  const ticketQuery = useFetchAll({
    queries: ticketQueries,
    combine: (results) => {
      const data = results
        .map((r, i) => {
          return {
            propertyId: propertyIds[i],
            tickets: r.data,
          };
        })
        .flat();
      return {
        data: data,
      };
    },
  });

  const ticketStatsQuery = useFetch({
    queryFn: fetchHandler({
      useCase: "DescribeTicketStats",
      query: query,
      headers: ticketReqHeaders,
    }),
    queryKey: [...TICKETS, "stats", query],
    enabled: opts.includeStats,
    refetchOnWindowFocus: true,
    retry: false,
  });

  return {
    ...ticketQuery,
    stats: ticketStatsQuery,
  };
};

export const useTicket = (id = null, propertyId = null) => {
  const { currentPropertyId } = useProperty();
  const pId = propertyId || currentPropertyId;
  const fetchOptions = createFetchOptions(pId, TICKET_QUERY_KEY);
  const { key: TICKETS, headers: ticketReqHeaders } = fetchOptions;

  const queryClient = useQueryClient();

  const updateTicketQueryCache = (ticket) => {
    if (!ticket) return;
    queryClient.setQueryData([...TICKETS, ticket.id], ticket);

    queryClient.setQueriesData({ queryKey: TICKETS }, (old) => {
      if (!old) return [ticket];

      if (Array.isArray(old)) {
        if (old.length === 0) return [ticket];
        return old.map((t) => {
          if (t.id === ticket.id) return ticket;
          return t;
        });
      } else if (old.id === ticket.id) return ticket;
    });
  };

  const ticketQuery = useFetch({
    queryFn: fetchHandler({
      useCase: "DescribeTicket",
      data: { id },
      headers: ticketReqHeaders,
      options: { firstOnly: true },
    }),
    queryKey: [...TICKETS, id],
    staleTime: 1000 * 60,
    refetchOnWindowFocus: true,
    enabled: Boolean(id),
    retry: 3,
    placeholderData: {},
  });

  const updateTicketMutation = useMutate({
    mutationFn: (ticket) => {
      const handler = fetchHandler({
        useCase: "UpdateTicket",
        data: ticket,
        headers: ticketReqHeaders,
        options: { firstOnly: true },
      });
      return handler();
    },
    onSuccess: updateTicketQueryCache,
  });

  const createTicketMessageMutation = useMutate({
    mutationFn: (vars) => {
      const handler = fetchHandler({
        useCase: "CreateTicketMessage",
        data: vars,
        headers: ticketReqHeaders,
        options: { firstOnly: true },
      });
      return handler();
    },
    onSuccess: updateTicketQueryCache,
  });

  const createTicketMutation = useMutate({
    mutationFn: (ticket) => {
      const handler = fetchHandler({
        useCase: "CreateTicket",
        data: ticket,
        headers: ticketReqHeaders,
        options: { firstOnly: true },
      });
      return handler();
    },
    retry: true,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: TICKETS });
    },
  });

  const updateTicketMessageReadStatusMutation = useMutate({
    mutationFn: (vars) => {
      if (!id) return Promise.resolve();
      const handler = fetchHandler({
        useCase: "UpdateMessageReadStatus",
        data: vars,
        headers: ticketReqHeaders,
        options: { firstOnly: true },
      });
      return handler();
    },
    mutationKey: ["updateMessageReadStatus", id],
    onSuccess: updateTicketQueryCache,
  });

  return {
    ...ticketQuery,
    updateTicket: updateTicketMutation,
    createMessage: createTicketMessageMutation,
    createTicket: createTicketMutation,
    markAllRead: updateTicketMessageReadStatusMutation,
  };
};
